<template>
  <div class="wout-login" @click="avancar">
    <div class="center">
      <!-- <div v-if="!loaded" class="loading-container">
        <div class="loading-spinner"></div>
      </div> -->
      <img
        v-if="loaded"
        src="@/assets/img/logowout.png"
        width="100%"
        height="auto"
        alt="Web Checkout!"
        crossorigin
        @load="onImageLoad"
      />
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      loaded: false,
    };
  },
  mounted() {
    //this.loaded = true;
    setTimeout(() => {
      this.loaded = true;
    }, 500);
  },
  methods: {
    onImageLoad() {
      this.loaded = true;
      setTimeout(() => {
        this.avancar();
      }, 2000);
    },
    avancar() {
      this.$emit("direcionar", { from: "introwout", to: "prelogin" });
    },
  },
};
</script>

<style>
.loading-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 9999;
}
.loading-spinner {
  border: 16px solid #f3f3f3; /* Light grey */
  border-top: 16px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.center {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  width: 70vw;
}
</style>
