<template>
  <v-dialog v-model="exibirModalWebCheckin" width="500px" max-width="800px">
      <v-card style="max-width: 100%">
           <v-toolbar color="blue" class="mb-8">
              <v-flex  style="display: flex" class="pa-2">
                  <!-- !--Ver a resolução movel-- -->
                  <span style="width:95%;color:white;font-weight:bold;" >
                      <v-icon large>mdi-whatsapp</v-icon> WhatsApp 
                  </span>
                  <span style="width:5%"> 
                      <v-btn x-small color="red" @click="setModalDirectWhatsApp" title="Fechar">
                          <v-icon x-small style="color:white;">mdi-close</v-icon>
                      </v-btn>
                  </span>
              </v-flex>
          </v-toolbar>

          <v-card-text>
              
              <v-card>
                  <div style="padding: 10px;">
                      <v-text-field
                          readonly
                          label="Para:"
                          v-model="numeroWhatsapp"
                      >
                      </v-text-field>
                  </div>

                  <div class="buttonEnviar">
                    <v-btn color="green" class="mb-2 mr-2" @click="enviarWhatsApp()">
                        <v-icon small color="white">mdi-send</v-icon>
                        <span style="color: white;">Enviar</span>
                    </v-btn>
                  </div>
                  <v-card-actions>
                      
                      <span style="min-width: 250px;"> <strong>{{ respostaAcao }}</strong> </span>
                      <v-spacer />
                      
                      
                      
                  </v-card-actions>
                  
                  
              </v-card>
              
          </v-card-text>

          
      </v-card>
  </v-dialog>
</template>          

<script>


export default {
  data: () => ({      
      mensagem : "mensagem",
      respostaAcao : "",
  }),
  props:["exibirModalWebCheckin", "numeroWhatsapp", "hospede", "dadosWebCheckin"],
  components:{
  },
  methods: {
      setModalDirectWhatsApp(){
          this.$emit('exibirModaWeb')
      },
      enviarWhatsApp(){
          if(this.numeroWhatsapp.length > 0){
            console.log(this.dadosWebCheckin)
            const url = `https://webcheckin.fd.economysoftware.com.br/${this.dadosWebCheckin.idHotelEs}/${this.dadosWebCheckin.idreserva}`
            console.log(url) 
                  const body = {
                    "telefoneDestino":this.numeroWhatsapp,
                    "template":"webcheckincomlink",
                    "dadosHeader":[
                      {"valor": this.hospede.nomehotel}
                    ],
                    "dadosBody":[
                      {"valor":this.hospede.nome},
                      {"valor": this.hospede.nomehotel},
                      {"valor": url}
                    ],
                    "dadosButton":[
                      
                    ]
                  }
                  console.log(body)
              this.$http.post('/whatsapp/enviarMensagem', body).then(resp =>{
                  console.log(resp)
                  this.respostaAcao = "Mensagem enviada"
                  setTimeout(() => { 
                      this.setModalDirectWhatsApp()
                  }, 2000);    
              })
          }else{
              this.respostaAcao = "Conteúdo da Mensagem não pode ser vazio."
          }
      },
      
  },
  mounted() {
    console.log(this.dadosWebCheckin, 'modal webcheckin')
      this.respostaAcao = ""
      this.mensagem = ""

  },
};
</script>

<style scoped>
.inputMensagem {
  background-color: white;
  border: 1px solid rgb(146, 146, 146);
  border-radius: 5px;
  padding: 2px;
  margin-left: 5px;
  margin-right: 5px;
  flex-grow: 1;
}

.containerMensagem {
  display: flex;
  align-items: center;
  margin-left: 10px;
  margin-right: 10px;
}

.buttonEnviar {
  margin-left: 10px;
  margin-top: 20px;
}
</style>