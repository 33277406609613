import Vue from 'vue'

// Components
import './components'

// Plugins
import './plugins'

// Sync router with store
import { sync } from 'vuex-router-sync'

// Application imports
import App from './App'
//import i18n from '@/i18n'
import router from '@/router'
import store from '@/store'
import Vuetify from 'vuetify'
import theme from './plugins/theme'
import 'vuetify/dist/vuetify.min.css'
import '@mdi/font/css/materialdesignicons.css'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import axios from 'axios'
import NProgress from "nprogress";
import VueSignaturePad from 'vue-signature-pad'
import VueSocketIO from 'vue-socket.io'
import SocketIO from "socket.io-client";
import moment from 'moment'
import VueGoogleAutocomplete from 'vue-google-autocomplete'


// Components
import './components'

Vue.use(VueSignaturePad);


//console.log("INICIANDO TUDO")

import { BUILD_FD_API_URL } from '@/constantes.js'

Vue.prototype.$http = axios

// axios.defaults.baseURL = (process.env.NODE_ENV == 'development') ? 'http://localhost:3335' : 'https://apifichadigital.economysoftware.com.br'
    axios.defaults.baseURL = BUILD_FD_API_URL

//console.log('axios.defaults.baseURL')
//console.log(axios.defaults.baseURL)

const { snack, snackE, snackW } = require('./utils/snack')
Vue.prototype.$snack = snack
Vue.prototype.$snackE = snackE
Vue.prototype.$snackW = snackW

import vueToPdf from 'vue-to-pdf';
import VueEasyPrinter from 'vue-easy-printer';
Vue.use(vueToPdf);
Vue.use(VueEasyPrinter);

Vue.use(
    new VueSocketIO({
        debug: true,
        // connection: SocketIO(axios.defaults.baseURL+'/fd',{query: { tipo:'CAPTURA_DOCUMENTOS' }, autoConnect: false}),
        connection: SocketIO(axios.defaults.baseURL + '/fd', { query: {}, autoConnect: false }),
        vuex: {
            store,
            actionPrefix: "SOCKET_",
            mutationPrefix: "SOCKET_",
        },
        // options: [{ transports: ["websocket"] }],
    })
);

axios.defaults.headers.get['Accept'] = 'application/json'
axios.defaults.headers.get['Accept'] = 'application/json; charset=utf-8'

axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';

Vue.prototype.moment = moment

const token = localStorage.getItem('token')
if (token) {
    axios.defaults.headers.common['x-access-token'] = token
}

Vue.config.productionTip = false

Vue.use(Vuetify, {
    iconfont: 'mdi',
    VueGoogleAutocomplete,
    theme
})


axios.interceptors.request.use(
    function(request) {
        // Do something before request is sent
        NProgress.start();
        return request;
    },
    function(error) {
        // Do something with request error
        console.log(error);
        NProgress.done();
        return Promise.reject(error);
    }
);

// Add a response interceptor
axios.interceptors.response.use(
    function(response) {
        NProgress.done();
        return response;
    },
    function(error) {
        // Do something with response error
        console.log(error);
        NProgress.done();
        return Promise.reject(error);
    }
);


import VueMask from 'v-mask'
import { VueMaskFilter } from 'v-mask'
Vue.use(VueMask);
Vue.filter('VMask', VueMaskFilter)

const {
    formatarData,
    formatarDataHora,
    formatarDataYYHora, 
    formatarDataYYHHMMSS, 
    formatarDataDDMM, 
    formatarDataDDMMAA,
    formatarMoeda 
} = require('./utils/formatador')

Vue.filter('formatarData', formatarData);
Vue.filter('formatarDataHora', formatarDataHora);
Vue.filter('formatarDataYYHora', formatarDataYYHora);
Vue.filter('formatarDataYYHHMMSS', formatarDataYYHHMMSS);
Vue.filter('formatarDataDDMM', formatarDataDDMM);
Vue.filter('formatarDataDDMMAA', formatarDataDDMMAA);
Vue.filter('formatarMoeda', formatarMoeda);

import i18n from './i18n'

sync(store, router)

new Vue({
    router,
    store,

    sockets: {
        connect: function() {
            //console.log('conectado')
        },
        disconnect: function() {
            //console.log('nao conectado')
        },
    },

    vuetify: new Vuetify(),
    i18n,
    render: h => h(App)
}).$mount('#app')