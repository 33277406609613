<template>
    <div class="wout-principal">
        <!-- Cabeçalho -->
        <!-- <div class="wout-topo">
            <div style="width:15%;">
                <button class="menu-voltar-btn" @click="voltar()">
                    <v-icon color="white">mdi-arrow-left</v-icon>
                </button>
            </div>
            <div class="white--text text-subtitle-2 px-2" style="width:70%;">
                <div v-if="dados.nome">{{dados.nome}}</div>
                <div class="wout-apto-reserva" v-if="dados">Apto.{{dados.uh}} Reserva: {{dados.numeroreserva}}</div>
            </div>
            <div class="wout-power">
                <v-icon style="font-size: 28px!important;" color="white" @click="logout">mdi-power</v-icon>
            </div>
        </div> -->

        <Header :nome="dados.nome" :uh="dados.uh" :numeroreserva="dados.numeroreserva"
            @voltar="voltar()" @logout="logout()"
        ></Header>

        <!-- Conteúdo -->
        <div class="wout-conteudo">
            <!-- Resumo -->
            <br>
            <div class="cartao" >
                <div class="wout-row">
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-in:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckinprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                    <div class="mx-2">
                        <div class="wout-label"><b>Data check-out:</b></div>
                        <div class="wout-campo">
                            {{dados.datacheckoutprevisto | formatarDataYYHora}}
                            <v-icon small>mdi-calendar</v-icon>
                        </div>
                    </div>
                </div>
            </div>
            <div class="toggler">Informe o consumo do frigobar<v-icon>mdi-chevron-down</v-icon></div>
            <!-- Itens do Frigobar -->
            <div class="cartao-item">
                <div class="wout-row">
                    <div class="item-label" style="width:8%;padding:0px;padding-left:0.5rem;">Cod.</div>
                    <div class="item-label" style="width:36%;padding:0px;padding-left:0.5rem;text-align:center;">Descrição</div>
                    <div class="item-label" style="width:20%;padding:0px;padding-left:0.5rem;">Valor(R$)</div>
                    <div class="item-label" style="width:11%;padding:0px;padding-left:0.2rem;">Taxas(%)</div>
                    <div class="item-label" style="width:23%;padding:0px;padding-left:0.5rem;">Quantidade</div>
                </div>
            </div>
            <div class="scrollable">
                <div class="cartao-item" v-for="(item, index) in dados.itens" :key="index">
                    
                    <div class="wout-row">
                        <div class="item-value" style="width:8%;">
                            <span>{{item.codproduto}}</span>
                        </div>
                        <div class="item-value" style="width:36%;">
                            <span>{{item.descprod}}</span>
                        </div>
                        <div class="item-money" style="width:20%;">
                            <span>{{item.preco | formatarMoeda}}</span>
                        </div>
                        <div class="item-value" style="width:11%;">
                            <span>{{item.taxas | formatarMoeda}}</span>
                        </div>
                        <div class="item-value" style="width:23%;">
                            <div class="col-btn">
                                <button @click="decrementar(index)">
                                    <v-icon color="blue">mdi-minus</v-icon>
                                </button>
                            </div>
                            <span class="px-1" :key="inputKey">{{item.quantidade}}</span>
                            <div class="col-btn"
                                style="align-content:center;"
                            >
                                <button @click="incrementar(index)">
                                    <v-icon color="red">mdi-plus</v-icon>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Registrar Consumo -->
            <div style="display:flex;justify-content:center;padding-top:3vh;">
                <button class="pagar-btn" @click="registrarConsumo()" :disabled="!possuiConsumo">
                    <v-icon>mdi-fridge-industrial-outline </v-icon>
                    Registrar Consumo
                    <v-icon>mdi-arrow-right</v-icon>
                </button>
            </div>
            <!-- Dialogo Informativo -->
            <v-dialog
                transition="dialog-bottom-transition"
                max-width="600" v-model="infoMessage"
            >
                <v-card rounded>
                    <v-card-text>
                        <div style="text-align:center;">
                            <img src="@/assets/img/contestwout.png" 
                                width="150" height="auto"
                                alt="Contesting..." crossorigin />
                                <br>
                            <div class="contest-message">Consumo registrado com sucesso!
                            </div>
                        </div>
                    </v-card-text>
                    <v-card-actions class="justify-end">
                        <!-- Voltar -->
                        <button class="voltar-btn"
                            v-on:click="fecharEVoltar()"
                            id="start_button"
                        >
                            <span class="voltar-icon">&nbsp;&nbsp;&nbsp;</span>
                            Voltar
                        </button>
                        <v-spacer/>
                    </v-card-actions>
                </v-card>
            </v-dialog>        

        </div>
    </div>
</template>

<script>
import Header from '@/components/webcheckout/Header'
export default {
    props:["dados"],
    data: () =>{
        return{
            inputKey: 0,
            possuiConsumo: false,
            infoMessage: false
        }
    },
    components: {
		Header
	},
    methods: {
        fecharEVoltar() {
            this.infoMessage = false
            this.$emit('direcionar', {to:'inicio', from:'consumofrigobar'})
        },
        incrementar(index) {
            this.dados.itens[index].quantidade = parseInt(this.dados.itens[index].quantidade) + 1
            this.inputKey++
            this.atualizarConsumo()
        },
        decrementar(index) {
            if (parseInt(this.dados.itens[index].quantidade) > 0) {
                this.dados.itens[index].quantidade = parseInt(this.dados.itens[index].quantidade) - 1
                this.inputKey++
                this.atualizarConsumo()
            }
        },
        atualizarConsumo() {
            var result = false
            for (var i = 0; i < this.dados.itens.length; i++) {
                if (parseInt(this.dados.itens[i].quantidade) > 0) {
                    result = true
                    break
                }
            }
            this.possuiConsumo = result
        },
        logout() {
            this.$emit('direcionar', {to:'login', from:'individual'})
        },
        voltar() {
            this.$emit('direcionar', {to:'inicio', from:'consumofrigobar'})
        },
        registrarConsumo() {
            var consumos = []
            for (var i = 0; i < this.dados.itens.length; i++) {
                var item = this.dados.itens[i]
                if (parseInt(item.quantidade) > 0) {
                    let valorSemTaxas = parseFloat(item.preco)*parseInt(item.quantidade)
                    let valorLancamento = valorSemTaxas + (valorSemTaxas * parseFloat(item.taxas) / 100)
                    consumos.push({
                        "idproduto": item.idproduto,
                        "iddepartamentohits": item.iddepartamentohits,
                        "codproduto": item.codproduto,
                        "precounitario": item.preco,
                        "quantidade": item.quantidade,
                        "descprod": item.descprod,
                        "taxas": item.taxas,
                        "valorsemtaxas": valorSemTaxas,
                        "vlrlancamento": valorLancamento,
                        "coduh": this.dados.uh,
                        "idlancamento":null,
                        "idhospede": this.dados.idhospedepms
                    })
                }
            }
            var params = {
                "consumos": consumos,
                "idreservahospede": this.dados.idreservahospede,
                "emailusuario": this.dados.email,
                "numeroreserva": this.dados.numeroreserva,
                "idhotel": this.dados.idhotel,
                "idhospedepms": this.dados.idhospedepms,
                "nome": this.dados.nome,
                "globalcodehits": this.dados.globalcodehits
            }
            console.error(JSON.stringify(params))
            // if (typeof params == 'string') {
                this.$http.post("/webcheckout/adicionarConsumoFrigobar", params, { headers: {'x-access-token': localStorage.getItem('checkout-token')}})
                    .then(result => {
                        if (result.status == 200) {
                            this.infoMessage = true
                            console.error(JSON.stringify(result.data))
                        } else {
                            console.error(JSON.stringify(result))
                        }
                    })
                .catch((e) => {
                    console.error(e)
                })
            // }    
        }
    },
    computed: {
        //
    },
    mounted(){
        //        
    }
}
</script>

<style>
    .col-btn {
        padding: 0px;
        margin-left: 5px;
        margin-right: 5px;
        background-color: azure;
        border-radius: 20px;
    }
</style>