import { render, staticRenderFns } from "./PagamentosBaixas.vue?vue&type=template&id=4cdddecb&scoped=true&"
import script from "./PagamentosBaixas.vue?vue&type=script&lang=js&"
export * from "./PagamentosBaixas.vue?vue&type=script&lang=js&"
import style0 from "./PagamentosBaixas.vue?vue&type=style&index=0&id=4cdddecb&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4cdddecb",
  null
  
)

export default component.exports