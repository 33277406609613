console.log('setando constantes')
let url = window.location.origin
if(url.includes('localhost')){
  console.log('setando url local ')
  url = 'http://localhost:3049'
}else{
    console.log('setando url remota')
    let arrayUrl = url.split('//') 
    url = `https://api${arrayUrl[1]}`
}

console.log('setando axios')
const axios = require('axios')
axios.post(url+'/ambiente/getDadosAmbiente').then(resp =>{

    let dadosAmbiente = resp.data

    BUILD_TIPO_INTEGRACAO = dadosAmbiente.BUILD_TIPO_INTEGRACAO
    BUILD_APIES_URL = 'https://apies.economysoftware.com.br'
    BUILD_ID_CLIENTE = dadosAmbiente.BUILD_ID_CLIENTE
    BUILD_PAIS_INICIAL_PADRAO = 1
    BUILD_ENVIAR_PAGAMENTO_CHECKOUT_AO_PMS = false
})



export var tokenapies = '5e47648febb18324243329f0bd0ef5e871ecd41f'
export var BUILD_TIPO_INTEGRACAO
export var BUILD_FD_API_URL=url
export var BUILD_APIES_URL= 'https://apies.economysoftware.com.br'
export var BUILD_ID_CLIENTE
export var BUILD_PAIS_INICIAL_PADRAO
export var BUILD_ENVIAR_PAGAMENTO_CHECKOUT_AO_PMS
 
