<template>
    <v-dialog v-model="exibirModalLogReserva" width="80vw" max-width="80vw">
        <v-card style="max-width: 100%">
             <v-toolbar color="blue" class="mb-8">
                <v-flex  style="display: flex" class="pa-2">
                    <!-- !--Ver a resolução movel-- -->
                    <span style="width:95%;color:white;font-weight:bold;" >
                        <v-icon large>mdi-eye</v-icon> Log da Reserva {{ logNumeroReserva }} 
                    </span>
                    <span style="width:5%"> 
                        <v-btn x-small color="red" @click="setModalLogReserva()" title="Fechar">
                            <v-icon x-small style="color:white;">mdi-close</v-icon>
                        </v-btn>
                    </span>
                </v-flex>
            </v-toolbar>
            
       
            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('pessoa') }" data-cy="btn-log-email" >Log de Pessoa
            </v-btn>
            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('endereco') }" data-cy="btn-log-email" >Log de Endereco
            </v-btn>
            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('reservahospede') }" data-cy="btn-log-email" >Log de Reserva X Hospede
            </v-btn>
            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('hospede') }" data-cy="btn-log-email" >Log de Hospede
            </v-btn>

            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('email') }" data-cy="btn-log-email" >Log de E-mail
            </v-btn>

            <v-btn justify-left class="v-btn light-blue ma-2" style="width:20%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('integracao')}" data-cy="btn-log-email" >Log de Integracao
            </v-btn>
            <v-btn justify-left class="v-btn light-blue ma-2" style="width:30%!important;color:#fff;font-weight:bold;" small
                    color="default" @click="() =>{ setVerLog('finalizacaoFicha')}" data-cy="btn-log-email" >Log de Finalização de ficha
            </v-btn>

                <v-card-text>

                <v-data-table :headers="headers" :items="loglist"
                    v-if="verLogReserva  && loglist && loglist.length > 0" class="elevation-1" no-data-text="Nenhum registro encontrado no log de reservas." >
                </v-data-table>

                <v-data-table :headers="headersLogPessoa" :items="loglistPessoa"
                    v-if="verLogPessoa  && loglistPessoa && loglistPessoa.length > 0" class="elevation-1" no-data-text="Nenhum registro encontrado no log de reservas." >
                </v-data-table>

                <v-data-table :headers="headersLogEndereco" :items="loglistEndereco"
                    v-if="verLogEndereco  && loglistEndereco && loglistEndereco.length > 0" class="elevation-1" no-data-text="Nenhum registro encontrado no log de reservas." >
                </v-data-table>

                
                <v-data-table :headers="headersLogReservaHospede" :items="loglistReservaHospede"
                    v-if="verLogReservaHospede  && loglistReservaHospede && loglistReservaHospede.length > 0" class="elevation-1" no-data-text="Nenhum registro encontrado no log de reservas." >
                </v-data-table>

                <v-data-table :headers="headersLogHospede" :items="loglistHospede"
                    v-if="verLogHospede  && loglistHospede && loglistHospede.length > 0" class="elevation-1" no-data-text="Nenhum registro encontrado no log de reservas." >
                </v-data-table>


                <v-data-table
                    :headers="headersLogEmail"
                    :items="loglistEmail"
                    v-if="verLogEmail  && loglistEmail && loglistEmail.length > 0"
                    class="elevation-1"
                    no-data-text="Nenhum registro encontrado no log de emails."
                >
                    <!-- <template v-slot:[`item.datahoraenvio`]="{ item }">
                        <span style="color:#1976d2;" data-cy="td-datahoraenvio">{{item.datahoraenvio | formatarDataYYHora}}</span>
                    </template>                  -->
                </v-data-table>

                <v-data-table
                    :headers="headersLogIntegracao"
                    :items="logIntegracao"
                    v-if="verLogIntegracao && logIntegracao && logIntegracao.length > 0"
                    class="elevation-1"
                    no-data-text="Nenhum registro encontrado nos logs de integração."
                >
                    <template v-slot:[`item.datahorarequisicao`]="{ item }">
                        <span style="color:#1976d2;" data-cy="td-datahorarequisicao">{{item.datahorarequisicao | formatarDataYYHora}}</span>
                    </template>                 
                </v-data-table>

                <v-data-table
                    :headers="headersLogFinalizacaoFicha"
                    :items="logFinalizacaoFicha"
                    v-if="verLogFinalizacaoFicha && logFinalizacaoFicha && logFinalizacaoFicha.length > 0"
                    class="elevation-1"
                    no-data-text="Nenhum registro encontrado nos logs de finalização de ficha."
                >
                    <template v-slot:[`item.birthdate`]="{ item }">
                        <span style="color:#1976d2;" data-cy="td-birthdate">{{item.birthdate | formatarData}}</span>
                    </template>
                    <template v-slot:[`item.dados`]="{ item }">
                        <v-btn
                          x-small
                          color="#00bb2d"
                          title="Ver dados"
                          @click="exibirDados(item.dados)"
                          ><v-icon small color="white">mdi-magnify</v-icon></v-btn
                        >
                    </template>                 
                </v-data-table>
            </v-card-text>

            
        </v-card>
        <ModalESObjeto
            :exibir="exibirModalDados"
            :titulo="`Log da Reserva - Dados enviados na finalização da ficha`"
            :objeto="objetoDados"
            @fechar="exibirModalDados = false"
        ></ModalESObjeto>
    </v-dialog>
</template>          

<script>
import ModalESObjeto from "./ModalESObjeto.vue";

export default {
    data: () => ({
        logIntegracao:[],
        logFinalizacaoFicha: [],
        verLogFinalizacaoFicha: false,
        verLogEmail:false,   
        verLogIntegracao:false,   
        verLogReserva:false,  
        verLogPessoa:true,
        verLogEndereco:false,
        verLogHospede:false,
        verLogReservaHospede:false ,
        loglist: [],
        headers: [
            { text: "Data", value: "datahoraevento", width: "120px", },
            { text: "Evento", value: "desceventolog", width: "120px", sortable: false},
            { text: "Usuário", value: "apelido", width: "120px" }
        ],
        loglistEmail: [],
        headersLogEmail: [
            { text: "Data", value: "datahoraenvio", width: "120px", },
            { text: "Email", value: "to", width: "120px", sortable: false},
            { text: "Assunto", value: "subject", width: "120px" }
        ],
        headersLogIntegracao: [
            { text: "Data", value: "datahorarequisicao", width: "200px", },
            { text: "Evento", value: "evento", width: "200px", sortable: false},
            { text: "Dados", value: "dados", width: "200px", sortable: false},
        ],
        headersLogFinalizacaoFicha: [
            { text: "Id", value: "idtable" },
            { text: "Nome", value: "name" },
            { text: "Id reserva", value: "idReservation"},
            { text: "Id Hospede PMS", value: "idEntity"},
            { text: "Tipo de Documento", value: "docType"},
            { text: "Documento", value: "doc"},
            { text: "Aniversário", value: "birthdate"},
            { text: "cidade", value: "addresses[0].city"},
            { text: "Dados enviados", value: "dados"},
        ],
        headersLogPessoa: [
            { text: "Data", value: "data_log" },
            { text: "Id", value: "id" },
            { text: "Nome", value: "nome"},
            { text: "Sobrenome", value: "sobrenome"},
            { text: "Nascimento", value: "nascimento"},
            { text: "Sexo", value: "sexo"},
            { text: "Profissao", value: "profissao"},
            { text: "Nacionalidade", value: "nacionalidade"},
            { text: "CPF", value: "cpf"},
        ],
        loglistPessoa: [],
        headersLogEndereco: [
            { text: "idlog", value: "idlog" },
            { text: "Id", value: "id" },
            { text: "Endereco", value: "endereco"},
            { text: "Numero", value: "numero"},
            { text: "Complemento", value: "complemento"},
            { text: "CEP", value: "cep"},
            { text: "Bairro", value: "bairro"},
            { text: "Cidade", value: "cidade"},
            { text: "Pais", value: "pais"},
            { text: "Alterado Por", value: "alteradopor"},
        ],
        loglistEndereco: [],
        headersLogHospede: [
            { text: "Data", value: "data_log" },
            { text: "Id Hospede no PMS", value: "idhospedepms" },
            { text: "Pessoa", value: "pessoa"},
            { text: "Tipo Doc", value: "tipodoc"},
            { text: "Doc Frente", value: "docfrente"},
            { text: "Doc Verso", value: "docverso"},
            { text: "Assinatura", value: "assinatura"}
        ],
        loglistHospede: [],
        headersLogReservaHospede: [
            { text: "Data", value: "data_log" },
            { text: "DT Checkin", value: "datacheckinprevisto" },
            { text: "DT Checkout", value: "datacheckoutprevisto"},
            { text: "Pais Ori", value: "paisorigem"},
            { text: "Cidade Ori", value: "cidadeorigem"},
            { text: "Pais Dest", value: "paisdestino"},
            { text: "Cdade Dest", value: "cidadedestino"},
            { text: "Motivo", value: "motivo"},
            { text: "Transporte", value: "transporte"},
            { text: "Emp Res.", value: "empresareservante"},
            { text: "Pensao", value: "tipopensao"},
            { text: "Dt Finalizado", value: "enviadopmstimestamp"},
            { text: "UH", value: "uh"},
            { text: "Ativo", value: "ativo"},
            { text: "Status", value: "idstatusreserva"},
            { text: "DT Envio WebCheckin", value: "dtenviowebcheckin"},
            { text: "Finalizada Por", value: "fichafinalizadapor"},
            { text: "Data Importacao", value: "dataimportacaopms"},
            { text: "Hosp. Principal", value: "principal"},
        ],
        loglistReservaHospede: [],
        exibirModalDados: false,
        objetoDados: {}
    }),
    props:["exibirModalLogReserva", "logNumeroReserva", "idReserva","idHospede", "emailReserva", "idpessoa"],
    components:{
        ModalESObjeto
    },
    methods: {
        exibirDados(dados) {
            this.objetoDados = Object.assign({}, JSON.parse(dados));
            this.exibirModalDados = true;
        },
        setVerLog(tipo){

            if(tipo === 'reserva'){

                this.verLogReserva = true 
                this.verLogEmail = false 
                this.verLogIntegracao = false 
                this.verLogFinalizacaoFicha = false
                this.carregar()
            }

            if(tipo === 'pessoa'){

                this.verLogPessoa = true 
                this.verLogEndereco = false 
                this.verLogHospede = false 
                this.verLogReservaHospede = false 
                this.verLogReserva = false 
                this.verLogEmail = false 
                this.verLogIntegracao = false 
                this.verLogFinalizacaoFicha = false
                this.carregarLogPessoa()
            }

            if(tipo === 'endereco'){

                this.verLogEndereco = true 
                this.verLogPessoa = false 
                this.verLogHospede = false 
                this.verLogReservaHospede = false 
                this.verLogReserva = false 
                this.verLogEmail = false 
                this.verLogIntegracao = false 
                this.verLogFinalizacaoFicha = false
                this.carregarLogEndereco()
            }

            if(tipo === 'hospede'){

                this.verLogHospede = true 
                this.verLogEndereco = false 
                this.verLogPessoa = false 
                this.verLogReservaHospede = false 
                this.verLogReserva = false 
                this.verLogEmail = false 
                this.verLogIntegracao = false 
                this.verLogFinalizacaoFicha = false
                this.carregarLogHospede()
            }

            if(tipo === 'reservahospede'){

                this.verLogReservaHospede = true 
                this.verLogHospede = false 
                this.verLogEndereco = false 
                this.verLogPessoa = false 
                this.verLogReserva = false 
                this.verLogEmail = false 
                this.verLogIntegracao = false 
                this.verLogFinalizacaoFicha = false
                this.carregarLogReservaHospede()
            }

            if(tipo === 'email'){
      
                this.verLogReservaHospede = false 
                this.verLogHospede = false 
                this.verLogEndereco = false 
                this.verLogPessoa = false 
                this.verLogEmail = true 
                this.verLogReserva = false 
                this.verLogIntegracao = false 
                this.verLogFinalizacaoFicha = false
                this.carregarLogEmails()
            }

            if(tipo === 'integracao'){

                this.verLogReservaHospede = false 
                this.verLogHospede = false 
                this.verLogEndereco = false 
                this.verLogPessoa = false 
                this.verLogIntegracao = true 
                this.verLogEmail = false 
                this.verLogReserva = false 
                this.verLogFinalizacaoFicha = false
                this.carregarLogIntegracao()
            }

            if(tipo === 'finalizacaoFicha'){
                this.verLogReservaHospede = false 
                this.verLogHospede = false 
                this.verLogEndereco = false 
                this.verLogPessoa = false 
                this.verLogFinalizacaoFicha = true 
                this.verLogEmail = false 
                this.verLogReserva = false 
                this.verLogIntegracao = false 
                this.carregarLogFinalizacaoFicha()
            }

        },
        setModalLogReserva(){
            this.$emit('setModalLogReserva')
        },
        carregar() {
            this.$http.post(`/logsistema/logreservaauxiliar`,{idReserva:this.idReserva, idHospede:this.idHospede})
            .then((response) => {
                this.loglist = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        carregarLogPessoa() {
            this.$http.post(`/logsistema/logPessoa`,{idReserva:this.idReserva, idHospede:this.idHospede})
            .then((response) => {
                this.loglistPessoa = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        carregarLogEndereco() {
            this.$http.post(`/logsistema/logEndereco`,{idReserva:this.idReserva, idHospede:this.idHospede})
            .then((response) => {
                this.loglistEndereco = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        carregarLogHospede() {
            this.$http.post(`/logsistema/logHospede`,{idReserva:this.idReserva, idHospede:this.idHospede})
            .then((response) => {
                this.loglistHospede = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        carregarLogReservaHospede() {
            this.$http.post(`/logsistema/logReservaHospede`,{idReserva:this.idReserva, idHospede:this.idHospede})
            .then((response) => {
                this.loglistReservaHospede = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        carregarLogEmails() {

            this.$http.post(`/logsistema/logemailreserva`,{emailReserva:this.emailReserva})
            .then((response) => {
                this.loglistEmail = response.data;
            })
            .catch((error) => {
                console.log(error);
            });

        },
        carregarLogIntegracao() {
            this.$http.post(`/logsistema/logintegracao`,{idReserva:this.idReserva, idHospede:this.idHospede,numeroreserva:this.logNumeroReserva})
            .then((response) => {
                this.logIntegracao = response.data;
            })
            .catch((error) => {
                console.log(error);
            });
        },
        
        carregarLogFinalizacaoFicha() {
            this.$http.post(`/logsistema/logfinalizacaoficha`,{idhospede: this.idHospede})
            .then((response) => {
                console.log('log finalizacao ficha', response.data)
                if(response.data && response.data.length > 0) {
                  
                    this.logFinalizacaoFicha = response.data.map((e) => {
                        let parse = JSON.parse(e.dados);
                        parse.idtable = e.id
                        parse.dados = e.dados
                        return parse
                    })
                }
                console.log(this.logFinalizacaoFicha);
            })
            .catch((error) => {
                console.log(error);
            });
        }
    },
    mounted() {
        this.carregar()
        this.carregarLogEmails()
        this.carregarLogIntegracao();
        this.carregarLogFinalizacaoFicha();
    },
};
</script>