<!-- This is the file that will render any external pages you do not want inside the dash, like login form-->
<template>
  <v-layout style="min-height: 90vh">
    <v-flex style="">
      <v-card class="pa-2" style="">
        <v-toolbar color="blue" class="ma-2" style="color: white">
          <div style="min-width:100%!important;display:flex;justify-content:space-between;">
              <span class="toolbar-title" style="width:80%;padding:10px;"> 
                Configurações
              </span> 
              <span style="width:5%;padding:10px;"> 
                <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Configurações"/>
              </span>
            </div>
        </v-toolbar>
        

        <div style="display:flex;justify-content:center;">
          <v-row class="ma-2" style="max-width: 70%;" >
            <v-card class="pa-2" style="width:100%;display:flex;">
              <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                <h3>Configurações de Gateways</h3>
              </div>
              <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                <v-btn @click="gotoGateways()" style="color:white;font-weight:bold;" color="primary">
                  Editar
                </v-btn>
              </div>
            </v-card>    
            
          </v-row>
        </div>   

        <div style="display:flex;justify-content:center;">
          <v-row class="ma-2" style="max-width: 70%;" >
            <v-card class="pa-2" style="width:100%;display:flex;">
              <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                <h3>Configurações Credito | Débito | Pix</h3>
              </div>
              <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                <v-btn @click="setModalDebCredPix()" 
                  style="color:white;font-weight:bold;" 
                  color="primary">
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>	 

        <div style="display:flex;justify-content:center;">
          <v-row class="ma-2" style="max-width: 70%;" >
            <v-card class="pa-2" style="width:100%;display:flex;">
              <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                <h3>Configurações de Envios Automáticos de Web Checkout</h3>
              </div>
              <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                <v-btn @click="setModalFollowUpWebCheckout()" 
                  style="color:white;font-weight:bold;" 
                  color="primary">
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>

        <div style="display:flex;justify-content:center;">
          <v-row class="ma-2" style="max-width: 70%;" >
            <v-card class="pa-2" style="width:100%;display:flex;">
              <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                <h3>Configurações de Taxas</h3>
              </div>
              <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                <v-btn @click="setModalTaxasCheckout()" 
                  style="color:white;font-weight:bold;" 
                  color="primary">
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>		

        <div style="display:flex;justify-content:center;">
          <v-row class="ma-2" style="max-width: 70%;" >
            <v-card class="pa-2" style="width:100%;display:flex;">
              <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                <h3>Configurar Obrigatoriedade da Checagem de Frigobar pelo Mensageiro</h3>
              </div>
              <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                <v-btn @click="setModalFrigobarWebCheckout()" 
                  style="color:white;font-weight:bold;" 
                  color="primary">
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>	

        <div style="display:flex;justify-content:center;">
          <v-row class="ma-2" style="max-width: 70%;" >
            <v-card class="pa-2" style="width:100%;display:flex;">
              <div style="width:70%;display:flex;justify-content:flex-start;align-items:center;">
                <h3>Configurar PDV de reservas</h3>
              </div>
              <div style="width:30%;display:flex;justify-content:flex-end;align-items:center;">
                <v-btn @click="buscarPDVsEAbrir()" 
                  style="color:white;font-weight:bold;" 
                  color="primary">
                  Editar
                </v-btn>
              </div>
            </v-card>
          </v-row>
        </div>	

      </v-card>
    </v-flex>
	
  
    <ModalFollowupWebCheckout
      @setModalFollowUpWebCheckout="() => setModalFollowUpWebCheckout()"
      :exibirModalFollowupWebCheckout ="exibirModalFollowupWebCheckout"
    />

    <ModalTaxasCheckout
      @setModalTaxasCheckout="() => setModalTaxasCheckout()"
      :exibirModalTaxasCheckout ="exibirModalTaxasCheckout"
    />
			
  
    <ModalFrigobarWebCheckout
      @setModalFrigobarWebCheckout="() => setModalFrigobarWebCheckout()"
      :exibirModalFrigobarWebCheckout ="exibirModalFrigobarWebCheckout"
    />
			
    <ModalDebCredPix
      @setModalDebCredPix="() => setModalDebCredPix()"
      :exibirModalDebCredPix ="exibirModalDebCredPix"
    />
			
    <ModalPDVReservas :pdvs="pdvs"
      @setModalPDVReservas="() => setModalPDVReservas()"
      :exibirModalPDVReservas ="exibirModalPDVReservas"
    />
    
  </v-layout>
</template>

<script>

import ModalFollowupWebCheckout from '@/components/DashViews/Modals/ModalFollowupWebCheckout'
import ModalTaxasCheckout from '@/components/DashViews/Modals/ModalTaxasCheckout'
import ModalFrigobarWebCheckout from '@/components/DashViews/Modals/ModalFrigobarWebCheckout'
import ModalDebCredPix from '@/components/DashViews/Modals/ModalDebCredPix'
import BotaoTutorial from '../material/BotaoTutorial.vue';
import ModalPDVReservas from '@/components/DashViews/Modals/ModalPDVReservas'

export default {
  data: () => ({
    
    exibirModalFollowupWebCheckout:false,
    exibirModalTaxasCheckout:false,
    exibirModalFrigobarWebCheckout:false,
    exibirModalDebCredPix:false,
    isWhatsAppSet: false,
    exibirModalPDVReservas:false,
    pdvs: []
  }),
  components:{
    ModalFollowupWebCheckout,
    ModalTaxasCheckout,
    ModalFrigobarWebCheckout,
    ModalDebCredPix,
    BotaoTutorial,
    ModalPDVReservas,
},
  methods: {
    gotoOpcionais(){
      this.$router.push("/dashboard/opcionais")
    },
    gotoGateways(){
      this.$router.push("/dashboard/gateways")
    },
    setModalEmailAniversario(){
      this.exibirModalEditarEmailAniversario =
        !this.exibirModalEditarEmailAniversario;
    },
    setModalEditarTermo(){	
      this.exibirModalEditarTermo = !this.exibirModalEditarTermo																					 
    },
    setModalEditarTermoLGPD(){	
      this.exibirModalEditarTermoLGPD = !this.exibirModalEditarTermoLGPD																					 
    },
    setModalGeo(){	
      this.exibirModalGeo = !this.exibirModalGeo																					 
    },
    setModalEditarFormSanitario(){
      this.exibirModalEditarFormSanitario = !this.exibirModalEditarFormSanitario
    },
    setModalEditarPassosWebCheckin(){
             this.exibirModalEditarPassosWebCheckin = !this.exibirModalEditarPassosWebCheckin
    },
    setModalFollowUpWebCheckout(){
             this.exibirModalFollowupWebCheckout = !this.exibirModalFollowupWebCheckout
    },
    setModalTaxasCheckout(){
             this.exibirModalTaxasCheckout = !this.exibirModalTaxasCheckout
    },
    setModalFrigobarWebCheckout(){
             this.exibirModalFrigobarWebCheckout = !this.exibirModalFrigobarWebCheckout
    },
    setModalDebCredPix(){
             this.exibirModalDebCredPix = !this.exibirModalDebCredPix
    },
    setModalEditarPassosWebCheckinPergunta(){
             this.exibirModalEditarPassosWebCheckinPergunta = !this.exibirModalEditarPassosWebCheckinPergunta
    },
    setModalEditarFelizAniversarioWhats() {
      this.exibirModalEditarMensagemFelizAniversario = !this.exibirModalEditarMensagemFelizAniversario;
    },
    setModalEditarAPIWhatsApp() {
      this.exibirModalEditarAPIWhatApp = !this.exibirModalEditarAPIWhatApp;
    },
    setModalEditarContaEmail() {
      this.exibirModalEditarContaEmail = !this.exibirModalEditarContaEmail;
    },
    setModalEditarAparencia(){
      this.exibirModalEditarAparencia = !this.exibirModalEditarAparencia
    },
    buscarPDVsEAbrir() {
      this.buscarPDVs().then(()=>{
        this.setModalPDVReservas(); 
      }) 
    },
    setModalPDVReservas(){
      this.exibirModalPDVReservas = !this.exibirModalPDVReservas
    },
    buscarDadosAPI() {
      this.$http
        .post("configuracao/buscarParametro", {
          idhotel: localStorage.getItem("hotel"),
          chave: "APIWHATSAPP",
        })
        .then((resp) => {
          if (resp.status == 200) {
            this.isWhatsAppSet = resp.data[0].valor == "true" ? true : false;
          } else {
            alert("Não foi possivel buscar os dados");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    async buscarPDVs() {
            this.$http.post("webcheckout/buscarPDVs", {  
                "idhotel" : localStorage.getItem('hotel'),
                "tipo_integracao" : localStorage.getItem('TIPOINTEGRACAO')
            }).then(resp =>{
                if(resp.status == 200){
                    this.pdvs = resp.data?.pdvs
                }else{
                    alert("Não foi possivel buscar os dados")
                }
            })
            .catch(error =>{
                console.log(error)
            })
        }

  },
  mounted() {
    console.log("POR AQUI");

    this.buscarDadosAPI();
  },
};
</script>

<style>
</style>
