<template>
  <v-container fill-height fluid grid-list-xl>
    <v-layout justify-center wrap>
      <v-flex md12>
        <v-card class="pa-2">
          <!-- Título -->
          <v-toolbar color="light-blue" class="ma-2"
            style="color: white;display:flex;flex-direction:column;padding-top:5px;padding-bottom:5px;">
            <div style="min-width:100%!important;display:flex;justify-content:space-between;">
              <span class="toolbar-title" style="width:80%;padding:10px;"> 
                Checagem Frigobar Web Checkout
              </span> 
              <span style="width:5%;padding:10px;"> 
                <BotaoTutorial urlVideo="" categoria="" legenda="Dúvidas Checagem Frigobar Web Ckeckout"/>
              </span>
              </div>
          </v-toolbar>
          <br>
          <!-- Filtro -->
          <v-row style="max-width: 100%;display:flex;align-items:center;justify-content:center;">
            <!-- Nome -->
            <v-text-field class="mr-1" type="text" label="Nome" 
              v-model="filtroNome"
              style="max-width:150px!important;"
            ></v-text-field>
            <!-- Sobrenome -->
            <v-text-field class="mr-1" type="text" label="Sobrenome"
              v-model="filtroSobrenome"
              style="max-width:150px!important;"
            ></v-text-field>
            <!-- Reserva -->
            <v-text-field class="ml-1 mr-1" type="text" label="Reserva"
              v-model="filtroReserva"
              style="max-width:100px!important;"
            ></v-text-field>
            <v-select
                    class="mr-2"
                    type="text"
                    label="Status"
                    v-model="filtroStatusPagto"
                    :items="listadestatus"
                    item-value="id"
                    item-text="nome"
                    return-object
                    style="max-width:140px!important;"
                  >
                  </v-select>

            <!-- Data Inicial -->
            <v-text-field class="mr-2" type="date" label="Check-IN Inicial"
              v-model="filtroDataInicial"
              style="max-width: 160px;"
            ></v-text-field>
            <!-- Data Final -->
            <v-text-field class="mr-2" type="date" label="Check-IN Final"
              v-model="filtroDataFinal"
              style="max-width: 160px;"
            ></v-text-field>
            
            <div style="display:flex;justify-content:center;padding:15px;">
              <!-- Botão Pesquisar -->
              <v-btn @click="loadPagamentos()" small color="primary" class="mx-2" :disabled="textoBtnPequisar != 'Pesquisar'"
                style="color:white;min-height:40px;font-weight:bold;"
              >
                <v-icon color="white" v-if="textoBtnPequisar == 'Pesquisar'">mdi-magnify</v-icon>
                {{textoBtnPequisar}}
              </v-btn>
              <!-- Botão Limpar Filtros -->
              <v-btn @click="limparFiltro()" small color="warning" class="mx-2" 
                style="color:white;min-height:40px;font-weight:bold;">
                <v-icon color="white">mdi-broom</v-icon>
                Limpar Filtros
              </v-btn>
            </div>
          </v-row>
          <v-spacer />
          <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          />
          <!-- Dados -->
          <v-data-table
            :headers="headers"
            :items="pagamentos"
            :footer-props="footerProps"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`footer.page-text`]="items"> 
              {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
            <template v-slot:[`item.datacheckinprevisto`]="{ item }">
              {{item.datacheckinprevisto | formatarDataYYHora}}
            </template>
            <template v-slot:[`item.datacheckoutprevisto`]="{ item }">
              {{item.datacheckoutprevisto | formatarData}}
            </template>
            <template v-slot:[`item.valortotal`]="{ item }">
              <div class="item-value">R$ {{item.valortotal | formatarMoeda}}</div>
            </template>
            <template v-slot:[`item.valoraberto`]="{ item }">
              <div class="item-value">{{item.valoraberto | formatarMoeda}}</div>
            </template>
            <template v-slot:[`item.valorpago`]="{ item }">
              <div class="item-value">{{item.valorpago | formatarMoeda}}</div>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <div class="item-group">
                <!-- <div class="item-action" title="Itens de consumo."
                  @click="carregarItens(item)">
                  <v-icon small color="purple" class="mx-1 ponteiro">
                    mdi-format-list-bulleted</v-icon>Itens
                </div> -->
                <div class="item-action" title="Confirmar frigobar verificado."
                  @click="confirmarBaixa(item)" v-if="!item.fl_checado">
                  <v-icon small color="green" class="mx-1">
                    mdi-check</v-icon>Marcar Como Verificado
                </div>
                <!-- <div class="item-action" title="Histórico de pagamentos."
                  @click="carregarHistorico(item)">
                  <v-icon small color="blue" class="mx-1">
                    mdi-playlist-check</v-icon>Histórico
                </div> -->
                <!-- <div class="item-action" title="Estornar pagamento."
                  @click="confirmarEstorno(item)">
                  <v-icon small color="red" class="mx-1">
                    mdi-close</v-icon>Negar
                </div> -->
              </div>
            </template>
          </v-data-table>
          <!-- Ítens do Pagamento -->
          <pagamentos-itens :items="itens" :show="mostrarItens" @closeitems="fecharItens" />
          <!-- Histórico do Pagamento -->
          <pagamentos-historico :items="historico" :show="mostrarHistorico" @closeitems="fecharHistorico" />
          <!-- Confirmar Baixa de Pagamento -->
          <pagamentos-confirmar :show="mostrarAceiteContestacao" 
            :titulo="'Confirmar que o Frigobar foi Checado'"
            :texto="'Clique em confirmar para marcar o Frigobar da reserva como Checado.'"
            @confirmar="confirmarChecagem" @cancelar="mostrarAceiteContestacao=false" />
          <!-- Confirmar Estorno de Pagamento -->
          <pagamentos-confirmar :show="mostrarNegacaoContestacao" 
            :titulo="'Confirmar a Negativa da Contestação'"
            :texto="'Clique em confirmar para Negar a contestatação.'"
            @confirmar="confirmarNegacao" @cancelar="mostrarNegacaoContestacao=false" />
          <!-- Snack -->
          <v-snackbar v-model="snack" :timeout="3000" :color="snackColor">
              <div style="display:flex;align-items:center;align-content:center;justify-content:space-between;">
                <span>{{ snackText }}</span>
                <v-btn style="background-color:white;color:grey;" @click="snack = false">FECHAR</v-btn>
              </div>
          </v-snackbar>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import dayjs from 'dayjs';
import PagamentosItens from './PagamentosItens.vue';
import PagamentosHistorico from './PagamentosHistorico.vue';
import PagamentosConfirmar from './PagamentosConfirmar.vue';
import BotaoTutorial from '../material/BotaoTutorial.vue';
export default {
  components: { PagamentosItens, PagamentosHistorico, PagamentosConfirmar, BotaoTutorial },
  data: () => ({
    listadestatus:[
      {id:1,nome:'Checado'},
      {id:2,nome:'Não Checado'}
    ],
    filtroStatusPagto:{id:2,nome:"Não Checado"},
    filtroReserva: null,
    filtroNome: null,
    filtroSobrenome: null,
    filtroDataInicial: null,
    filtroDataFinal: null,
    snack: false,
    snackColor: "",
    snackText: "",
    pagamentos: [],
    footerProps: {
      'items-per-page-options': [15,20,25,{ text: "Todas", value: -1 }],
      'items-per-page-text': 'Linhas por página',
    },
    dialog: false,
    search: "",
    headers: [
      { text: "Reserva", value: "numeroreserva" },
      { text: "UH", value: "uh" },
      { text: "Nome", value: "nome" },
      { text: "Sobrenome", value: "sobrenome" },
      { text: "Checkout", value: "datacheckoutprevisto" },
      { text: "Solicitado", value: "datacriacao" },
    //   { text: "Valor", value: "valortotal", align: 'end' },
    //   { text: "Valor em Aberto", value: "valoraberto", align: 'end' },
    //   { text: "Pagamento", value: "valorpago", align: 'end' },      
      { text: "Ações", value: "actions", sortable: false, align: 'center' }
    ],
    editedIndex: -1,
    editedItem: {},
    textoBtnPequisar: 'Pesquisar',
    itens: [],
    mostrarItens: false,
    historico: [],
    mostrarHistorico: false,
    mostrarAceiteContestacao: false,
    itemAAceitar: {},
    mostrarNegacaoContestacao: false,
    itemAEstornar: {}
  }),
  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    dialog(val) {
      val || this.close();
    },
  },
  created() {
    this.loadPagamentos();
  },
  methods: {
    carregarItens(item) {
      var params = {idpagamento: item.id}
      this.$http.post("/webcheckout/getItensPagamentos", params)
      .then((response) => {
        if (response.status == 200) {
          this.itens = response.data
          this.mostrarItens = true
        } else {
          console.error(response)
        }
      })
      .catch((error) => {          
        console.log(error)
      });
    },
    fecharItens() {
      this.itens = []
      this.mostrarItens = false
    },
    carregarHistorico(item) {
      var params = {idpagamento: item.id}
      this.$http.post("/webcheckout/getDetalhePagamentos", params)
      .then((response) => {
        if (response.status == 200) {
          this.historico = response.data
          this.mostrarHistorico = true
        } else {
          console.error(response)
        }
      })
      .catch((error) => {          
        console.log(error)
      });
    },    
    fecharHistorico() {
      this.historico = []
      this.mostrarHistorico = false
    },
    confirmarBaixa(item) {
        // alert(JSON.stringify(item))
      this.itemAAceitar = item
      this.mostrarAceiteContestacao = true
    },
    confirmarChecagem() {
        var params = {
            idhotel: this.itemAAceitar.hotel,
            numeroreserva: this.itemAAceitar.numeroreserva,
            idchecagem: this.itemAAceitar.idchecagem,
            emailhospede: this.itemAAceitar.email,
            nomehospede: this.itemAAceitar.nome
        }
         
        this.$http.post("/webcheckout/marcarChecagemConcluida", params)
        .then((response) => {
            if (response.status == 200) {
            this.snackText = 'Checagem realizada.'
            this.snackColor = 'green'
            this.snack = true
            this.loadPagamentos()
            } else {
            console.error(response)
            }
            this.mostrarAceiteContestacao = false
        })
        .catch((error) => {          
            console.log(error)
            this.mostrarAceiteContestacao = false
        });
    },
    confirmarEstorno(item) {
      this.itemAEstornar = item
      this.mostrarNegacaoContestacao = true
    },    
    confirmarNegacao() {
        
      var params = {
        idhotel: this.itemAEstornar.hotel,
        numeroreserva: this.itemAEstornar.numeroreserva,
        idcabecalho: this.itemAEstornar.idcabecalho,
        iddetalhe: this.itemAEstornar.iddetalhe,
        emailhospede: this.itemAAceitar.email,
        nomehospede: this.itemAAceitar.nome
      }
    //   alert(JSON.stringify(params))
      console.error(params)
      this.$http.post("/webcheckout/negarContestacao", params)
      .then((response) => {
        if (response.status == 200) {
          this.snackText = 'Estorno Negado.'
          this.snackColor = 'green'
          this.snack = true
          this.loadPagamentos()
        } else {
          console.error(response)
        }
        this.mostrarNegacaoContestacao = false
      })
      .catch((error) => {          
        console.log(error)
        this.mostrarNegacaoContestacao = false
      });
    },    
    limparFiltro() {
      this.filtroReserva = null
      this.filtroNome = null
      this.filtroSobrenome = null
      this.filtroDataInicial = null
      this.filtroDataFinal = null
    },
    loadPagamentos() {
      this.textoBtnPequisar = "Pesquisando..."

      if(!this.filtroDataInicial && !this.filtroReserva && !this.filtroNome && !this.filtroSobrenome){
        this.filtroDataInicial = dayjs().format('YYYY-MM-DD')
      }
      if(!this.filtroDataFinal && !this.filtroReserva && !this.filtroNome && !this.filtroSobrenome){
        this.filtroDataFinal = dayjs().format('YYYY-MM-DD')
      }

      var params = {
          idhotel:localStorage.getItem('hotel'),
          datacheckoutinicial: this.filtroDataInicial,
          datacheckoutfinal: this.filtroDataFinal,
          statuschecagem: this.filtroStatusPagto.id == 1? true:false,
          nome: this.filtroNome,
          sobrenome: this.filtroSobrenome,
          numeroreserva: this.filtroReserva
      }
      try {
        //console.error(params)
        this.$http
        .post("/webcheckout/getChecagensFrigobar",params)
        .then((response) => {
          if (response.status == 200) {
            this.pagamentos = response.data
          }
        })
        .catch((error) => {          
          console.log(error)
        });
      } finally {
          this.textoBtnPequisar = "Pesquisar"
          // this.pagamentos = [
          //   {
          //     nome: 'Fulano de tal',
          //     reserva: '32145654',
          //     datacheckinprevisto: '2021-10-01 10:00',
          //     datacheckoutprevisto: '2021-10-07 16:00',
          //     valortotal: '120.50',
          //     valoremaberto: '11227.1',
          //     pagamento: '.59'
          //   },
          //   {
          //     nome: 'Ciclano de tal',
          //     reserva: '34446754',
          //     datacheckinprevisto: '2021-10-02 12:00',
          //     datacheckoutprevisto: '2021-10-09 16:00',
          //     valortotal: '1212420',
          //     valoremaberto: '0',
          //     pagamento: '2020'
          //   },
          //   {
          //     nome: 'Beltrano de tal',
          //     reserva: '34111759',
          //     datacheckinprevisto: '2021-10-06 12:00',
          //     datacheckoutprevisto: '2021-10-12 16:00',
          //     valortotal: '3224.20',
          //     valoremaberto: '1004.29',
          //     pagamento: '2024'
          //   },
          // ];
      }
    },
    focus(id) {
      var idEl = document.getElementById(id)
      if (idEl) idEl.focus()
    },
    deleteItem(item) {
      if (confirm("Are you sure you want to delete this item?")) {
        this.editedItem = Object.assign({}, item);
      }
    },
    close() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    fecharAtualizacao() {
      this.dialog = false;
      setTimeout(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      }, 300);
    },
    saveInline() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Registro salvo com Sucesso!";
    },
    cancelInline() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Operação Cancelada";
    },
    reset() {
      this.snack = true;
      this.snackColor = "success";
      this.snackText = "Data reset to default";
    },
    openInline() {
      this.snack = true;
      this.snackColor = "info";
      this.snackText = "Dialog opened";
    },
    errorSave() {
      this.snack = true;
      this.snackColor = "error";
      this.snackText = "Erro ao salvar usuário";
    },
    closeInline() {
      console.log("Dialog closed");
    },
  },
};
</script>
<style scoped>
.item-group{
  display: flex;
  flex-direction: row;
}
.item-action{
  cursor: pointer;
  font-size: 0.7rem!important;
  margin-left: 0.3rem;
  margin-right: 0.3rem;
}
.item-value{
  font-size: 0.8rem!important;
}
</style>