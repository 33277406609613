<template>
  <v-dialog v-model="exibirModalControleDeQuartos" width="1000" max-width="80vw">
    <v-card style="max-width: 100%">
      <v-toolbar color="blue" class="mb-8">
        <v-flex style="display: flex" class="pa-2">
          <span style="width:95%;color:white;font-weight:bold;">
            Controle de acesso de quartos
          </span>
          <span style="width:5%">
            <v-btn id="fecharModalContrato" x-small color="red" @click="() => this.$emit('fecharModalControleAcessoQuartos')" title="Fechar">
              <v-icon x-small style="color:white;">mdi-close</v-icon>
            </v-btn>
          </span>
        </v-flex>
      </v-toolbar>

      <v-card-text>
        <v-switch
        label="Liberar envios de senhas"
        v-model="liberarEnviosSenhas"
        @change="alterarLiberarEnviosSenhas"
        style="border-radius: 5px; padding: 5px"
        ></v-switch>
        <div style="width: 50%; display: flex; justify-content: center; align-items: center;">
          <v-text-field
            id="select-empresa-reservante"
            style="margin: 5px !important;"
            class="pa-0"
            hide-details
            label="Nome"
            return-object
            outlined
            v-model.trim="nome_acesso"
          >
          </v-text-field>
          <v-text-field
            id="select-empresa-reservante"
            style="margin: 5px !important;"
            class="pa-0"
            hide-details
            label="Senha de acesso"
            return-object
            outlined
            v-model.trim="senha_portao"
          >
          </v-text-field>
          <v-btn color="blue" style="color: white; margin: 0 0 0 10px" :disabled="loading" @click="cadastrarAcessoPortao"> {{ btnSalvar }}</v-btn>
        </div>
        <div style="width: 100%; height: 2px; background: #cccccc; margin: 10px 0 10px 0;">

        </div>
        <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
          <v-text-field
            style="margin: 5px !important;"
            class="pa-0"
            hide-details
            label="Número do quarto"
            return-object
            outlined
            v-model.trim="quarto"
          >
          </v-text-field>
          <v-text-field
            style="margin: 5px  !important;"
            class="pa-0"
            hide-details
            label="Senha de acesso"
            outlined
            v-model.trim="senha_acesso"
          >
          </v-text-field>
          <v-btn color="blue" style="color: white; margin: 0 0 0 10px" @click="cadastrarAcessoQuarto" :disabled="loading">{{  btnCadastrar  }}</v-btn>
        </div>
        <v-data-table
          :headers="headers"
          :items="listaAcessoQuartos"
          class="elevation-1 ma-2"
          no-data-text="Nenhum controle de acesso a quartos cadastrado"
        >
          <template v-slot:[`item.acoes`]="{ item }">
              <button class="buttonEditarVisualizar" style="margin-right: 5px;" @click="exibirModaleditarAcessoQuarto(item)">Editar</button>
              <button class="buttonEditarVisualizar" style="background: red;" @click="deletarAcessoQuarto(item)">Deletar</button>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="exibirEditarAcessoQuarto" width="500" max-width="40vw">
      <v-card style="max-width: 100%">
        <v-toolbar color="blue" class="mb-8">
          <v-flex style="display: flex" class="pa-2">
            <span style="width:95%;color:white;font-weight:bold;">
             Editar acesso quarto
            </span>
            <span style="width:5%">
              <v-btn x-small color="red" @click="() => this.exibirEditarAcessoQuarto = false" title="Fechar">
                <v-icon x-small style="color:white;">mdi-close</v-icon>
              </v-btn>
            </span>
          </v-flex>
        </v-toolbar>
        <v-card-text>
          <v-text-field
            style="margin: 5px !important;"
            class="pa-0"
            hide-details
            label="Número do quarto"
            return-object
            outlined
            v-model.trim="quarto_editar"
            disabled="true"
          >
          </v-text-field>
          <v-text-field
            style="margin: 5px  !important;"
            class="pa-0"
            hide-details
            label="Senha de acesso"
            outlined
            v-model.trim="senha_acesso_editar"
          >
          </v-text-field>
          <v-btn color="blue" style="color: white; margin: 0 0 0 10px" @click="editarAcesso" :disabled="loading">{{  btnEditar  }}</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-dialog>
</template>

<script>
  export default {
    props: ['exibirModalControleDeQuartos'],
    data() {
      return {
        listaAcessoQuartos: [],
        headers: [
          { text: "Id", value: "id" },
          { text: "Quarto", value: "quarto" },
          { text: "Senha de acesso", value: "senha_acesso" },
          { text: "Ações", value: "acoes" },
        ],
        quarto: null,
        senha_acesso: null,
        quarto_editar: null,
        senha_acesso_editar: null,
        senha_portao: null,
        nome_acesso: null,
        btnCadastrar: 'Cadastrar',
        btnSalvar: 'Salvar',
        loading: false,
        liberarEnviosSenhas: false,
        exibirEditarAcessoQuarto: false,
        id_editar: null,
        btnEditar: 'Salvar'
      }
    },
    methods: {
      async cadastrarAcessoQuarto() {
        if (!this.quarto) return alert('Quarto obrigatório');
        if (!this.senha_acesso) return alert('Senha de acesso obrigatório');
        const verificarQuartoJaCadastrado = this.listaAcessoQuartos.some((e) => e.quarto.trim() === this.quarto);
        if (verificarQuartoJaCadastrado) return alert('Quarto já cadastrado!');
        try {
          this.loading = true;
          this.btnCadastrar = 'Aguarde...'
          await this.$http.post('/acesso-quartos/cadastrar/acesso-quarto', {
            quarto: this.quarto,
            senha_acesso: this.senha_acesso
          })
          alert('Quarto cadastrado com sucesso!');
          await this.buscarAcessosQuartos();
        } catch (error) {
          console.log(error)
          alert('Erro ao cadastrar acesso do quarto');
        }
        this.btnCadastrar = 'Cadastrar'
        this.loading = false;
      },

      async buscarAcessosQuartos() {
        try {
          const result = await this.$http.post('/acesso-quartos/buscar/acessos-quartos');
          console.log(result.data)
          this.listaAcessoQuartos = result.data;
        } catch (error) {
          alert('Erro ao buscar lista de acessos de quartos');
        }
      },

      async cadastrarAcessoPortao() {
        if (!this.senha_portao) return alert('Senha do portão obrigatório');
        if (!this.nome_acesso) return alert('Nome obrigatório');
        try {
          this.btnSalvar = 'Aguarde...'
          this.loading = true;
          await this.$http.post('/acesso-quartos/cadastrar/acessos-portao-principal', {
            senha_acesso: this.senha_portao,
            nome_acesso: this.nome_acesso
          })
          alert('Acesso do portão principal cadastrado com sucesso');
        } catch (error) {
          alert('Erro ao cadastrar acesso do portão principal');
        }
        this.btnSalvar = 'salvar'
        this.loading = false;
      },

      async buscarAcessoPortao() {
        try {
          const result = await this.$http.post('/acesso-quartos/buscar/acessos-portao-principal');
          this.senha_portao = result?.data[0]?.valor
          this.nome_acesso = result?.data[0]?.valor2
        } catch (error) {
          alert('Erro ao buscar acesso do portão principal');
        }
      },

      async buscarLiberarEnviosSenhas() {
        try {
          const result = await this.$http.post('/acesso-quartos/buscar/liberar-envios-acesso');
          console.log(result);
          this.liberarEnviosSenhas = result?.data?.valor
        } catch (error) {
          alert('Erro ao buscar configuração de liberação de envios de senhas no webcheckin');
        }
      },

      async alterarLiberarEnviosSenhas() {
        try {
           await this.$http.post('/acesso-quartos/alterar-liberar-envios-acesso', {
            config: this.liberarEnviosSenhas === true ? 'true' : 'false'
          });
          alert('Configuração atualizda com sucesso');
        } catch (error) {
          console.log(error)
          alert('Erro ao atualizar configuração');
        }
      },

      async deletarAcessoQuarto(item) {
        try {
           await this.$http.post('/acesso-quartos/deletar/acessos-quartos', {
            id: item.id
          });
          alert('Acesso deletado com sucesso!');
          await this.buscarAcessosQuartos();
        } catch (error) {
          alert('Erro ao deletar acesso');
        }
      },

      async exibirModaleditarAcessoQuarto(item) {
        this.id_editar = item.id
        this.senha_acesso_editar = item.senha_acesso
        this.quarto_editar = item.quarto
        this.exibirEditarAcessoQuarto = true;
      },

      async editarAcesso() {
          try {
          this.btnEditar = 'Aguarde...'
          await this.$http.post('/acesso-quartos/update/acessos-quartos', {
            id: this.id_editar,
            quarto: this.quarto_editar,
            senha_acesso: this.senha_acesso_editar
          });
          alert('Acesso atualizado com sucesso!');
          await this.buscarAcessosQuartos();
        } catch (error) {
          alert('Erro ao editar acesso do quarto')
        }
        this.exibirEditarAcessoQuarto = false;
        this.id_editar = null;
        this.quarto_editar = null;
        this.senha_acesso_editar = null;
        this.btnEditar = 'Salvar'
      }
    },
    async mounted() {
      await Promise.all([this.buscarAcessosQuartos(), this.buscarAcessoPortao(), this.buscarLiberarEnviosSenhas()])
    }
  }

</script>

<style scoped>
.buttonEditarVisualizar {
  background-color: #2B81D6;
  color: white;
  padding: 5px;
  border-radius: 5px;
  font-size: 10px!important;
}
</style>